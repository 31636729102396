<template>
  <Card class="p-col">
    <template #title>

      <div class="p-d-flex p-jc-between">
        <div>
          <h3 class="p-m-0">
            {{ _t('label_Trigger_ad_hoc') }}
            <span
                v-tooltip.right="{ value: _t('label_fill_in_to_activate_button'), disabled: manualCallEnabled }">
                  <Button
                      :disabled="!manualCallEnabled"
                      :label="_t('label_Trigger')"
                      class="p-button-primary p-ml-6"
                      @click="executeManualCall()"
                  />
                </span>
          </h3>
        </div>
      </div>
    </template>
    <template #content>
      <TabView v-model:activeIndex="activeTab">
        <TabPanel :header="_t('ALARM')">
          <div class="p-grid">
            <div class="p-col-3" style="vertical-align: middle;">
              <p>
                {{ _t('label_Ad_hoc_Select_existing_alarm') }}
              </p>
            </div>
            <div class="p-col-6" style="vertical-align: middle;">
              <div class="p-inputgroup">
                <span class="p-inputgroup-addon" :style="`background-color: ${selectedAlarmPoint.icon.color}; color: white; border-color: ${selectedAlarmPoint.icon.color}; border-radius: 5px; border-bottom-right-radius: 0px; border-top-right-radius: 0px;`">
                  <i :class="selectedAlarmPoint.icon.name" style="font-size: 17px;"></i>
                </span>
                <Dropdown v-model="selectedAlarmPoint.id"
                          :filter="true"
                          :filterPlaceholder="_t('label_Select_alarm')"
                          :options="alarmPoints"
                          class="p-m-0"
                          dataKey="id"
                          optionLabel="name"
                          optionValue="id"
                          style="width:100%; align-items: center;"
                          panel-class="alarms-dropdown-panel"
                          ref="alarmPointDropdown"
                          @change="getAlarmPoint($event.value)"
                          @filter="getAlarmPoints($event.value)"
                >
                  <template #option="slotProps">
                    <div class="p-dropdown-option ellipsis" style="width: 100%; max-width: 525px;">
                      <span>{{ slotProps.option.alarmPointId }}: {{
                          slotProps.option.name
                        }} {{ slotProps.option.systemId ? '[' + slotProps.option.systemId + ']' : '' }}</span>
                    </div>
                  </template>
                </Dropdown>
                <span class="p-inputgroup-addon">
                      <i v-tooltip.bottom="_t('Clear')"
                         class="pi pi-times"
                         @click="initAlarmPoint"></i>
                    </span>
              </div>
            </div>
            <div class="p-col-12">
              <h3 class="subhead p-mt-4">&nbsp;</h3>
            </div>
            <div class="p-col-12" style="vertical-align: middle;">
              <div class="p-grid">
                <div class="p-col-3" style="vertical-align: middle;">
                  <p>{{ _t('label_Alarm_name') }}<sup>*</sup></p>
                </div>
                <div class="p-col-9" style="vertical-align: middle;">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="pi pi-pencil"></i>
                    </span>
                    <InputText v-model="selectedAlarmPoint.name"
                               :placeholder="_t('label_Alarm_name')"
                               class="p-m-0"
                               maxlength="255" style="width:100%;"
                    />
                  </div>
                </div>
                <div class="p-col-3 stripedBg" style="vertical-align: middle;">
                  <p>{{ _t('label_message_priority') }}<sup>*</sup></p>
                </div>
                <div class="p-col-3 stripedBg" style="vertical-align: middle;">
                  <Dropdown v-model="selectedAlarmPoint.confirmPriority"
                            :options="[1,2,3,4,5,6,7,8,9,10]"
                            class="p-m-0"
                            style="width: 100%;"/>
                </div>
                <div class="p-col-6 stripedBg" style="vertical-align: middle;"></div>
                <div class="p-col-3" style="vertical-align: middle;">
                  <p>{{ _t('label_alarm_text') }}<sup>*</sup></p>
                  <SelectTextTemplate @handleSave="handleSaveTextTemplate"/>
                  <div class="p-inputgroup p-mt-5">
                    <span class="p-inputgroup-addon">
                      <i class="pi pi-envelope"></i>
                    </span>
                    <Dropdown v-model="selectedTextVar"
                              :options="textVars"
                              :placeholder="_t('select_predefined_variable')"
                              class="p-m-0"
                              style="width: 100%;"
                              @change="copyTextVar"
                    />
                  </div>
                </div>
                <div class="p-col-9" style="vertical-align: middle;">
                  <Textarea v-model="selectedAlarmPoint.text"
                            :placeholder="_t('label_alarm_text')"
                            class="p-m-0"
                            maxlength="2048"
                            rows="5"
                            style="width:100%"/>
                  <br/>
                  <small v-if="alarmPointError.includes('selectedAlarmPoint.text')" class="p-error">Text is
                    required.</small>
                  <p>{{ _t('label_speech_text') }}</p>
                  <SpeechText :text="selectedAlarmPoint.text"/>
                </div>
                <div v-if="!selectedAlarmPoint.id" class="p-col-3">
                  <p>{{ _t('label_instruction_files') }}</p>
                </div>
                <div v-if="!selectedAlarmPoint.id" class="p-col-5">
                  <Dropdown
                      v-model="selectedAlarmPoint.instructionFile"
                      :options="instructions"
                      :placeholder="_t('label_Select_attachment')"
                      class="p-m-0"
                      optionLabel="name"
                      optionValue="path"
                      panel-class="alarms-dropdown-panel"
                      style="width: 100%;"
                  >
                    <template #option="slotProps">
                      <div class="p-dropdown-option ellipsis" style="width: 100%; max-width: 495px;">
                        <span v-if="slotProps.option.org.id != 1">
                          {{ slotProps.option.org.fullOrgPath.replace('[Total system]-', '') }} -
                        </span> {{ slotProps.option.name }}
                      </div>
                    </template>
                  </Dropdown>
                </div>
                <div v-if="!selectedAlarmPoint.id" class="p-col-4">
                  <Button
                      :disabled="!selectedAlarmPoint.instructionFile"
                      :label="_t('label_assign')"
                      class="p-button-success"
                      @click="addInstruction"
                  />
                  <span style="float: right"><UploadFile @uploaded="loadInstructions"/></span>
                </div>
                <div
                    v-if="selectedAlarmPoint.instructionFiles && selectedAlarmPoint.instructionFiles.length > 0"
                    class="p-col-12"
                >
                  <div
                      v-for="(instruction, index) in selectedAlarmPoint.instructionFiles"
                      :key="index"
                  >
                    <Button :label="instruction.split('/').pop()"
                            class="p-button-link p-button-text p-button-success"
                            @click="downloadInstruction(instruction)"
                    />
                    <Button
                        class="p-button-rounded p-button-danger p-button-text"
                        icon="pi pi-times"
                        @click="deleteInstruction(instruction)"
                    />
                  </div>
                </div>
                <div class="p-col-12">
                  <h3 class="subhead p-mt-4">&nbsp;</h3>
                </div>

                <Accordion class="p-col-12" style="width: 100%">
                  <AccordionTab :header="_t('label_Other_parameters')">
                    <div class="p-grid">
                      <div class="p-col-3 stripedBg" style="vertical-align: middle;">
                        <p>{{ _t('label_speech_text') }}</p>
                        <Button :label="_t('label_copy_from_alarmtext')"
                                class="p-button p-button-secondary p-mt-4"
                                @click="selectedAlarmPoint.speechText = selectedAlarmPoint.text"/>
                      </div>
                      <div class="p-col-9 stripedBg" style="vertical-align: middle;">
                  <Textarea v-model="selectedAlarmPoint.speechText"
                            :placeholder="_t('label_speech_text')"
                            class="p-m-0"
                            maxlength="2048"
                            rows="5"
                            style="width:100%"/>
                      </div>
                      <div class="p-col-3" style="vertical-align: middle;">
                        <p>{{ _t('label_media') }}</p>
                      </div>
                      <div class="p-col-3" style="vertical-align: middle;">
                        <Dropdown v-model="selectedAlarmPoint.mediaUsageType"
                                  :options="mediaUsageTypes"
                                  class="p-m-0"
                                  optionLabel="label"
                                  optionValue="value"
                                  style="width: 100%;"/>
                      </div>
                      <div class="p-col-6" style="vertical-align: middle;"></div>
                      <div class="p-col-3 stripedBg" style="vertical-align: middle;">
                        <p>{{ _t('label_confirm_mode') }}</p>
                      </div>
                      <div class="p-col-3 stripedBg" style="vertical-align: middle;">
                        <Dropdown v-model="selectedAlarmPoint.acknowledgeMode"
                                  :options="confirmationModes"
                                  class="p-m-0"
                                  optionLabel="label"
                                  optionValue="value"
                                  style="width: 100%;"/>
                      </div>
                      <div class="p-col-6 stripedBg" style="vertical-align: middle;"></div>
                      <div class="p-col-3" style="vertical-align: middle;">
                        <p>{{ _t('label_description') }}</p>
                      </div>
                      <div class="p-col-9" style="vertical-align: middle;">
                  <Textarea v-model="selectedAlarmPoint.description"
                            :placeholder="_t('label_description')"
                            class="p-m-0"
                            maxlength="512"
                            rows="5"
                            style="width:100%"/>
                      </div>
                    </div>
                  </AccordionTab>
                </Accordion>
                <div class="p-col-3 stripedBg" style="vertical-align: middle;">
                  <p>{{ _t('label_organization') }}</p>
                </div>
                <div class="p-col-9 stripedBg" style="vertical-align: middle;">
                  <OrganizationLink ref="orgLink" v-model="selectedAlarmPoint" :editMode="true"/>
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel :header="_t('PARTICIPANTS')">
          <div class="p-grid">
            <div class="p-col-3" style="vertical-align: middle;">
              <div>
                <div v-for="(option, i) in srcOptions" :key="option" class="p-field-radiobutton">
                  <RadioButton :id="'srcOption'+i"
                               v-model="srcOption"
                               :value="option.value"
                               name="srcOption"
                               @change="syncSrcOptions"
                  />
                  <label :for="'srcOption'+i">{{ option.label }}</label>
                </div>
              </div>
            </div>
            <div v-if="srcOption=='EXIST'" class="p-col-9" style="vertical-align: middle;">
              <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="pi pi-exclamation-triangle"></i>
                    </span>
                <Dropdown v-model="selectedAlarmPoint.alarmPlanId"
                          :filter="true"
                          :filterPlaceholder="_t('label_Select_plan')"
                          :options="alarmPlans"
                          class="p-m-0"
                          optionLabel="name"
                          optionValue="id"
                          style="width:100%;"
                          @change="getAlarmPlan($event.value)"
                />
                <span class="p-inputgroup-addon">
                      <i v-tooltip.bottom="_t('Clear')"
                         class="pi pi-times"
                         @click="selectedAlarmPlan = {id: null}; selectedAlarmPoint.alarmPlanId = null"></i>
                    </span>
              </div>
            </div>
            <div v-if="srcOption=='NEW'" class="p-col-9" style="vertical-align: middle;">
              <div class="p-grid">
                <div class="p-col-4 stripedBg">
                  <p>{{ _t('label_Participant_plan_name') }}<sup>*</sup></p>
                </div>
                <div class="p-col-8 stripedBg">
                  <div class="p-inputgroup">
                        <span class="p-inputgroup-addon">
                          <i class="pi pi-pencil"></i>
                        </span>
                    <InputText v-model="selectedAlarmPlan.name" :placeholder="_t('label_Participant_plan_name')"
                               class="p-m-0"
                               style="width:100%;"/>
                  </div>
                </div>
                <div class="p-col-4">
                  <p>{{ _t('label_Participant_plan_description') }}</p>
                </div>
                <div class="p-col-8">
                  <div class="p-inputgroup p-mt-2">
                        <span class="p-inputgroup-addon">
                          <i class="pi pi pi-pencil"></i>
                        </span>
                    <InputText v-model="selectedAlarmPlan.description"
                               :placeholder="_t('label_Participant_plan_description')"
                               class="p-m-0" style="width:100%;"/>
                  </div>
                </div>
                <div class="p-col-4 stripedBg">
                  <p>{{ _t('label_organization') }}</p>
                </div>
                <div class="p-col-8 stripedBg">
                  <OrganizationLink v-model="selectedAlarmPlan" :editMode="true"/>
                </div>
              </div>
            </div>
          </div>
          <h3 class="subhead">{{ _t('label_Target_groups') }}</h3>
          <TabView v-model:activeIndex="activeTabSimple">
            <TabPanel :header="_t('label_Standard_view')">
              <div v-if="selectedAlarmPlan.callGroups && selectedAlarmPlan.callGroups.length > 0"
                   class="p-grid">
                <Card
                    v-for="(callGroup, index) in selectedAlarmPlan.callGroups"
                    :key="callGroup"
                    class="p-mr-2 p-mb-2"
                    style="display:inline-block; width:25rem; font-size: 12px;"
                >
                  <template #content>
                    <div class="p-grid">
                      <div class="p-col-9">
                        <div class="p-inputgroup">
                      <span class="p-inputgroup-addon">
                        <i class="pi pi-pencil"></i>
                      </span>
                          <InputText v-model="selectedAlarmPlan.callGroups[index].name"
                                     :placeholder="_t('label_group_name')" class="p-m-0" style="width:100%;"/>
                        </div>
                      </div>
                      <div class="p-col-3 p-text-right">
                        <Button class="p-button-danger"
                                @click="initCallGroupDeleteDialog(index)">
                          <i class="pi pi-trash"></i>
                        </Button>
                      </div>
                      <div class="p-col-7">
                        {{ _t('label_Notification_method') }}
                      </div>
                      <div class="p-text-right p-col-5">
                        <Dropdown v-model="selectedAlarmPlan.callGroups[index].memberCallRule"
                                  :options="callRules"
                                  class="p-m-0"
                                  optionLabel="label"
                                  optionValue="value"
                                  style="width:100%;" @change="syncGroupSelectRule(selectedAlarmPlan.callGroups[index])"
                        />
                      </div>
                      <div class="p-col-7">
                        <span>{{ _t('label_Number_required_ack') }}</span>
                      </div>
                      <div class="p-text-right p-col-5">
                        <InputText v-model="selectedAlarmPlan.callGroups[index].atLeastCount"
                                   class="p-m-0" min="0"
                                   style="width: 100%;" type="number"
                        />
                      </div>
                      <div class="p-col-7">
                        {{ _t('label_Method_participants_selection') }}
                      </div>
                      <div class="p-text-right p-col-5">
                        <Dropdown v-model="selectedAlarmPlan.callGroups[index].memberSelectRule"
                                  :options="selectRules"
                                  class="p-m-0"
                                  optionLabel="label"
                                  optionValue="value"
                                  style="width:100%;" @change="syncGroupSelectRule(selectedAlarmPlan.callGroups[index])"
                        />
                      </div>
                      <div class="p-col-8" style="vertical-align: middle;">
                        <h3 class="subhead">{{ _t('PARTICIPANTS') }}</h3>
                      </div>
                      <div class="p-col-4 p-text-right">
                  <span class="p-buttonset">
                    <Button v-tooltip.bottom="_t('label_Edit_participants')"
                            class="p-button-success p-button-icon-only p-button-text"
                            @click="initCallGroupEditDialog(selectedAlarmPlan.callGroups[index])">
                      <i class="pi pi-plus-circle"></i>
                    </Button>
                    <Button
                        v-tooltip.bottom="_t('label_Edit_participants')"
                        :disabled="!selectedCallGroupMembers[selectedAlarmPlan.callGroups[index].id] || selectedCallGroupMembers[selectedAlarmPlan.callGroups[index].id].length < 1"
                        class="p-button-danger p-button-icon-only p-button-text"
                        @click="removeCallGroupMembers(selectedAlarmPlan.callGroups[index])">
                      <i class="pi pi-minus-circle"></i>
                    </Button>
                  </span>
                      </div>
                      <div class="p-col-12">
                        <DataTable
                            v-model:selection="selectedCallGroupMembers[selectedAlarmPlan.callGroups[index].id]"
                            :scrollable="true"
                            :value="selectedAlarmPlan.callGroups[index].members"
                            class="p-m-0 condensed"
                            dataKey="id"
                            scrollHeight="20rem"
                            selectionMode="multiple"
                            style="width:100%; font-size: 12px;"
                            @row-reorder="reorderCallGroupMembers(selectedAlarmPlan.callGroups[index], $event.value)"
                        >
                          <Column :rowReorder="true" style="flex: 0 0 2rem"/>
                          <Column field="title"
                          >
                            <template #body="slotProps">
                              <i v-if="slotProps.data.participantId" class="pi pi-user p-mr-1"></i>
                              <i v-else class="pi pi-users p-mr-1"></i>
                              <span v-tooltip.top="{
                                      value: `${slotProps.data.title} ${slotProps.data.shortName ? `(${slotProps.data.shortName})` : ''}`,
                                      disabled:  ((+slotProps.data.title.length)  + (+ slotProps.data.shortName ? slotProps.data.shortName.length : 0)) <= 33
                              }"
                                    class="ellipsis">{{
                                  slotProps.data.title
                                }}
                              {{ `${slotProps.data.shortName ? `(${slotProps.data.shortName})` : ''}` }}</span>
                            </template>
                          </Column>
                          <Column selectionMode="multiple" style="flex: 0 0 2rem"/>
                        </DataTable>
                      </div>
                    </div>
                  </template>
                </Card>
                <div class="p-ml-2 p-mt-2" style="display: inline-block;">
                  <Button v-tooltip="_t('label_Add_new_target_group')" class="p-button-success p-button-icon"
                          icon="pi pi-plus"
                          @click="newCallGroupDialog = true; newCallGroup.name = this._t('label_group') + ' ' + (selectedAlarmPlan.callGroups ? selectedAlarmPlan.callGroups.length + 1 : 1)"/>
                </div>
              </div>
              <div v-else>
                <p>{{ _t('label_No_target_groups') }}</p>
                <div class="p-ml-2 p-mt-2" style="display: inline-block;">
                  <Button v-tooltip="_t('label_Add_new_target_group')" class="p-button-success p-button-icon"
                          icon="pi pi-plus"
                          @click="newCallGroupDialog = true; newCallGroup.name = this._t('label_group') + ' ' + (selectedAlarmPlan.callGroups ? selectedAlarmPlan.callGroups.length + 1 : 1)"/>
                </div>
              </div>
            </TabPanel>
            <TabPanel :header="_t('label_Simplified_view')">
              <div v-if="selectedAlarmPlan.callGroups && selectedAlarmPlan.callGroups.length > 0"
                   class="p-grid">
                <Card
                    v-for="(callGroup, index) in selectedAlarmPlan.callGroups"
                    :key="callGroup.id"
                    class="p-mr-2 p-mb-2"
                    style="display:inline-block; width:25rem; font-size: 12px;"
                >
                  <template #content>
                    <div class="p-grid">
                      <div class="p-col-9">
                        <div class="p-inputgroup">
                      <span class="p-inputgroup-addon">
                        <i class="pi pi-pencil"></i>
                      </span>
                          <InputText v-model="selectedAlarmPlan.callGroups[index].name"
                                     :placeholder="_t('label_group_name')" class="p-m-0" style="width:100%;"/>
                        </div>
                      </div>
                      <div class="p-col-3 p-text-right">
                        <Button class="p-button-danger"
                                @click="initCallGroupDeleteDialog(index)">
                          <i class="pi pi-trash"></i>
                        </Button>
                      </div>
                      <div class="p-col-12">
                        <p><strong>{{ _t('label_select_notification_method') }}</strong></p>
                        <div v-for="(rule, i) in callRulesSimple"
                             :key="rule.value"
                             :class="['p-field-radiobutton', (rule.value == 'OTHER' ? 'hidden' : '')]">
                          <RadioButton :id="'call_rule'+i"
                                       v-model="selectedAlarmPlan.callGroups[index].memberCallRuleSimple"
                                       :value="rule.value"
                                       name="callRule"
                                       @change="syncGroupSelectRuleSimple(selectedAlarmPlan.callGroups[index])"/>
                          <label :for="'call_rule'+i">{{ rule.label }}</label>
                        </div>
                      </div>
                      <div class="p-col-8">
                        <span>{{ _t('label_Number_required_ack') }}</span>
                      </div>
                      <div class="p-col-4 p-text-right">
                        <InputText v-model="selectedAlarmPlan.callGroups[index].atLeastCount"
                                   class="p-m-0" min="0"
                                   style="width: 100%;" type="number"/>
                      </div>
                      <div class="p-col-8" style="vertical-align: middle;">
                        <h3 class="subhead">{{ _t('PARTICIPANTS') }}</h3>
                      </div>
                      <div class="p-col-4 p-text-right">
                  <span class="p-buttonset">
                    <Button v-tooltip.bottom="_t('label_Edit_participants')"
                            class="p-button-success p-button-icon-only p-button-text"
                            @click="initCallGroupEditDialog(selectedAlarmPlan.callGroups[index], true)">
                      <i class="pi pi-plus-circle"></i>
                    </Button>
                    <Button
                        v-tooltip.bottom="_t('label_Edit_participants')"
                        :disabled="!selectedCallGroupMembers[selectedAlarmPlan.callGroups[index].id] || selectedCallGroupMembers[selectedAlarmPlan.callGroups[index].id].length < 1"
                        class="p-button-danger p-button-icon-only p-button-text"
                        @click="removeCallGroupMembers(selectedAlarmPlan.callGroups[index], true)">
                      <i class="pi pi-minus-circle"></i>
                    </Button>
                  </span>
                      </div>
                      <div class="p-col-12">
                        <DataTable
                            v-model:selection="selectedCallGroupMembers[selectedAlarmPlan.callGroups[index].id]"
                            :scrollable="true"
                            :value="callGroup.members"
                            class="p-m-0 condensed"
                            dataKey="id"
                            scrollHeight="20rem"
                            selectionMode="multiple"
                            style="width:100%; font-size: 12px;"
                            @row-reorder="reorderCallGroupMembers(selectedAlarmPlan.callGroups[index], $event.value)"
                        >
                          <Column :rowReorder="true" style="flex: 0 0 2rem"/>
                          <Column field="title"
                          >
                            <template #body="slotProps">
                              <i v-if="slotProps.data.participantId" class="pi pi-user p-mr-1"></i>
                              <i v-else class="pi pi-users p-mr-1"></i>
                              <span v-tooltip.top="{
                                      value: `${slotProps.data.title} ${slotProps.data.shortName ? `(${slotProps.data.shortName})` : ''}`,
                                      disabled: ((+slotProps.data.title.length)  + (+slotProps.data.shortName ? slotProps.data.shortName.length : 0)) <= 33
                              }"
                                    class="ellipsis">{{ slotProps.data.title }}
                                {{ `${slotProps.data.shortName ? `(${slotProps.data.shortName})` : ''}` }}</span>
                            </template>
                          </Column>
                          <Column selectionMode="multiple" style="flex: 0 0 2rem"/>
                        </DataTable>
                      </div>
                    </div>
                  </template>
                </Card>
                <div class="p-ml-2 p-mt-2" style="display: inline-block;">
                  <Button v-tooltip="_t('label_Add_new_target_group')" class="p-button-success p-button-icon"
                          icon="pi pi-plus"
                          @click="newCallGroupDialog = true; newCallGroup.name = this._t('label_group') + ' ' + (selectedAlarmPlan.callGroups ? selectedAlarmPlan.callGroups.length + 1 : 1)"/>
                </div>
              </div>
              <div v-else>
                <p>{{ _t('label_No_target_groups') }}</p>
                <div class="p-ml-2 p-mt-2" style="display: inline-block;">
                  <Button v-tooltip="_t('label_Add_new_target_group')" class="p-button-success p-button-icon"
                          icon="pi pi-plus"
                          @click="newCallGroupDialog = true; newCallGroup.name = this._t('label_group') + ' ' + (selectedAlarmPlan.callGroups ? selectedAlarmPlan.callGroups.length + 1 : 1)"/>
                </div>
              </div>
            </TabPanel>
          </TabView>
        </TabPanel>
        <TabPanel :header="_t('label_Icon')">
          <div v-if="!selectedAlarmPoint.id" class="p-grid">
            <div class="p-col-12">
              <h3 class="subhead">{{ _t('label_Colour') }}</h3>
            </div>
            <div v-for="(color, i) in iconColors" :key="color" class="p-col-1 p-text-center">
              <label :for="`color${i}`">
                <div :style="`background-color: ${color};`" class="color_sample p-mb-2"/>
              </label>
              <RadioButton :id="`color${i}`" v-model="selectedAlarmPoint.icon.color" :disabled="!!selectedAlarmPoint.id"
                           :value="color"
                           name="color"/>
            </div>
            <div class="p-col-12">
              <h3 class="subhead">{{ _t('label_Icon') }}</h3>
            </div>
            <Card v-for="(section, s) in icons" :key="section.label" class="p-col-3">
              <template #title>
                <p>{{ section.label }}</p>
              </template>
              <template #content>
                <div class="p-grid">
                  <div v-for="(icon, i) in section.icons" :key="icon" class="p-col-3 p-text-center">
                    <label :for="`icon_${s}_${i}`">
                      <i :class="`icon p-mb-2 ${icon}`"/>
                    </label><br>
                    <RadioButton :id="`icon_${s}_${i}`" v-model="selectedAlarmPoint.icon.name"
                                 :disabled="!!selectedAlarmPoint.id" :value="icon"
                                 name="icon"/>
                  </div>
                </div>
              </template>
            </Card>
          </div>
          <div v-else>
            <i :class="`icon_big p-mb-2 ${selectedAlarmPoint.icon.name}`"
               :style="`color: #fff; background-color: ${selectedAlarmPoint.icon.color}; font-size: 4rem; border-radius: 5px;`"/>
          </div>
        </TabPanel>
      </TabView>
    </template>
  </Card>

  <Dialog v-model:visible="newCallGroupDialog">
    <template #header>
      <h3>{{ _t('label_Add_new_target_group') }}</h3>
    </template>
    <div class="p-inputgroup">
      <span class="p-inputgroup-addon">
        <i class="pi pi pi-pencil"></i>
      </span>
      <InputText v-model="newCallGroup.name" :placeholder="_t('label_target_group_name')" class="p-m-0"
                 style="width: 100%;"/>
    </div>
    <div class="p-mt-2">
      <p>{{ _t('label_organization') }}:
        <OrganizationLink v-model="selectedAlarmPlan" :editMode="false"/>
      </p>
      <p>{{ _t('label_alarm_plan') }}: <strong>{{ selectedAlarmPlan.name }}</strong></p>
    </div>
    <template #footer>
      <Button :label="_t('Cancel')" class="p-button-outlined p-button-secondary" @click="newCallGroupDialog = false"/>
      <Button :label="_t('Save')" autofocus class="p-button-success" @click="createCallGroup()"/>
    </template>
  </Dialog>

  <Dialog v-model:visible="editCallGroupDialog">
    <template #header>
      <h3>{{ _t('label_Update_participants') }}</h3>
    </template>
    <p>{{ _t('label_Update_participants_for_target_group') }} <strong class="color-green">{{
        originalCallGroup.name
      }}</strong></p>
    <ParticipantSelector v-model="editCallGroup.members" style="width:30vw;"/>
    <template #footer>
      <Button :label="_t('Cancel')" class="p-button-outlined p-button-secondary" @click="editCallGroupDialog = false"/>
      <Button :label="_t('Save')" autofocus class="p-button-success" @click="updateCallGroupMembers()"/>
    </template>
  </Dialog>

  <Dialog v-model:visible="deleteCallGroupDialog">
    <template #header>
      <h3>{{ _t('Delete') }}</h3>
    </template>
    <p>Do you want to delete target group <strong class="color-green">{{
        selectedAlarmPlan.callGroups[deleteCallGroupIndex].name && selectedAlarmPlan.callGroups[deleteCallGroupIndex].name.length ? selectedAlarmPlan.callGroups[deleteCallGroupIndex].name : '[No title]'
      }}</strong>?</p>
    <template #footer>
      <Button :label="_t('Cancel')" class="p-button-text" icon="pi pi-times" @click="deleteCallGroupDialog = false"/>
      <Button :label="_t('Delete')" autofocus icon="pi pi-check" @click="deleteCallGroup()"/>
    </template>
  </Dialog>


</template>
<style scoped>
.hidden {
  display: none;
}

.subhead::after {
  border-width: 2px !important;
}
</style>
<style>
.p-listbox.grp .p-listbox-list .p-listbox-item {
  padding-left: 2em;
}

.p-tree.tight .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler {
  margin-right: -0.5rem;
  width: 1.2rem;
  height: 1.7rem;
}

.p-tree.tight .p-treenode-label {
  max-width: 215px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.color_sample {
  display: block;
  width: 48px;
  height: 48px;
  border-radius: 4px;
  margin: 0 auto;
}

.icon_big {
  font-size: 48px;
  padding: 4px;
  border-radius: 4px;
}

.icon_medium {
  font-size: 24px;
  padding: 4px;
  border-radius: 4px;
}

.icon {
  font-size: 24px;
}

.ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 215px;
  white-space: nowrap;
}
</style>

<style lang="scss">
.alarms-dropdown-panel {
  .p-dropdown-items-wrapper {
    max-height: 400px!important;
  }
}
</style>

<script>
import {getList} from '@/api/list'
import {getUser} from '@/api/user'
import {getAlarmPoint} from '@/api/alarmPoint'
import {getAlarmPlan} from '@/api/alarmPlan'
import OrganizationLink from '@/components/ixarma/OrganizationLink'
import ParticipantSelector from '@/components/ixarma/ParticipantSelector'
import {manualCall} from '@/api/manualCall'
import {downloadInstruction} from '@/api/instruction'
import {getInstructionFiles} from '@/api/attachments'
import SpeechText from '@/components/speechText';
import SelectTextTemplate from '@/components/SelectTextTemplate.vue'

export default {
  name: 'AlarmTrigger',
  components: {
    OrganizationLink,
    ParticipantSelector,
    SpeechText,
    SelectTextTemplate,
  },
  props: {
    selectedAlarmPointId: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      alarmPoints: [],
      alarmPointError: [],
      selectedAlarmPoint: {
        id: null,
        icon: {
          name: '',
          color: ''
        },
        confirmPriority: 1
      },
      selectedTextVar: null,
      textVars: [
        '<original>', '<K>', '<G>', '<family>', '<callerid>'
      ],
      confirmationModes: [],
      mediaUsageTypes: [],
      mediaProcessingTypes: [],
      triggerings: [],
      srcOptions: [],
      srcOption: null,
      repeatableMonitored: false,
      activeTabSimple: 0,
      selectedAlarmPlan: {
        id: null
      },
      alarmPlans: [],
      selectedCallGroupMembers: {},
      callRules: [],
      callRulesSimple: [],
      selectRules: [],
      participants: [],
      participantGroups: [],
      newCallGroupDialog: false,
      newCallGroup: {},
      editCallGroupDialog: false,
      originalCallGroup: null,
      editCallGroup: {
        participants: [],
        participantGroups: [],
      },
      deleteCallGroupDialog: false,
      deleteCallGroupIndex: -1,
      participantFilterValue: '',
      orgId: localStorage.getItem('orgId') ?? 1,
      iconColors: [
        '#3996f3',
        '#314c5d',
        '#fad165',
        '#85b44a',
        '#8c5cfd',
        '#46c1d3',
        '#e23e57',
        '#989898',
        '#1b499e',
        '#f5856d'
      ],
      featuredIcons: [
        'mdi mdi-alert-outline',
        'mdi mdi-alert-box-outline',
        'mdi mdi-exit-to-app',
        'mdi mdi-fire',
        'mdi mdi-water-alert-outline',
        'mdi mdi-hospital-box-outline',
        'mdi mdi-wrench-outline',
        'mdi mdi-lan-disconnect',
        'mdi mdi-alarm-light-outline',
        'mdi mdi-shield-alert-outline',
        'mdi mdi-account-alert-outline',
        'mdi mdi-lock-open-outline',
        'mdi mdi-help-circle-outline',
        'mdi mdi-information-outline'
      ],
      icons: [],
      alarmPointsGrp: [],
      regularCalendar: {
        events: [{
          "resourceId": "d",
          "title": "event 1",
          "start": "2021-09-23T12:30:00+00:00",
          "end": "2021-09-25T15:30:00+00:00"
        }, {
          "resourceId": "c",
          "title": "event 3",
          "start": "2021-09-24T12:30:00+00:00",
          "end": "2021-09-25T06:45:00+00:00"
        }, {
          "resourceId": "f",
          "title": "event 4",
          "start": "2021-09-24T07:30:00+00:00",
          "end": "2021-09-24T09:30:00+00:00"
        }, {
          "resourceId": "b",
          "title": "event 5",
          "start": "2021-09-24T10:00:00+00:00",
          "end": "2021-09-24T15:00:00+00:00"
        }, {
          "resourceId": "e",
          "title": "event 2",
          "start": "2021-09-24T09:00:00+00:00",
          "end": "2021-09-24T14:00:00+00:00"
        }],
      },
      activeTab: 0,
      instructions: [],
      loadedAlarmPointsPage: 0,
      loading: true,
      loadedAlarmPointsCount: 999999,
      userGroups: [],
      isAvailableGetAlarmPoints: true,
      prefillTextTemplateDialog: false,
      userGroupPage: 0,
      lazyLoadUGRecords: true
    }
  },
  mounted() {
    this.mediaProcessingTypes = [
      {value: 'CONFERENCE_CALL', label: this._t('CONFERENCE_CALL')},
      {value: 'BY_RANK', label: this._t('BY_RANK')}
    ]
    this.triggerings = [
      {value: 'WHEN_ON', label: this._t('WHEN_ON')},
      {value: 'WHEN_ON_AND_OFF', label: this._t('WHEN_ON_AND_OFF')}
    ]
    this.mediaUsageTypes = [
      {value: 'ALL', label: this._t('label_as_configured')},
      {value: 'VOICE', label: this._t('label_voice_only')},
      {value: 'TEXT', label: this._t('label_text_only')}
    ]
    this.confirmationModes = [
      {value: 'NONE', label: this._t('_NONE')},
      {value: 'MEDIA', label: this._t('_MEDIA')},
      {value: 'PARTICIPANT', label: this._t('_PARTICIPANT')},
      {value: 'MEDIA_AND_PARTICIPANT', label: this._t('_MEDIA_AND_PARTICIPANT')}
    ]
    this.icons = [
      {
        label: this._t('menu.general'),
        icons: [
          'mdi mdi-alert-outline',
          'mdi mdi-bell-ring-outline',
          'mdi mdi-alarm',
          'mdi mdi-alert-rhombus-outline',
          'mdi mdi-bell-alert-outline',
          'mdi mdi-car-brake-alert',
          'mdi mdi-alert-circle-outline',
          'mdi mdi-alert-octagon-outline',
          'mdi mdi-alert-decagram-outline',
          'mdi mdi-alert-box-outline',
          'mdi mdi-weather-cloudy-alert',
          'mdi mdi-alarm-multiple',
          'mdi mdi-bell-circle-outline',
        ]
      },
      {
        label: this._t('label_Alarms'),
        icons: [
          'mdi mdi-exit-run',
          'mdi mdi-exit-to-app',
          'mdi mdi-fire',
          'mdi mdi-fire-extinguisher',
          'mdi mdi-air-horn',
          'mdi mdi-fire-hydrant',
          'mdi mdi-water-alert-outline',
          'mdi mdi-water-remove-outline',
          'mdi mdi-pipe-leak',
          'mdi mdi-ambulance',
          'mdi mdi-medical-bag',
          'mdi mdi-hospital-box-outline',
          'mdi mdi-zodiac-aquarius',
          'mdi mdi-wrench-outline',
          'mdi mdi-server-off',
          'mdi mdi-lan-disconnect',
          'mdi mdi-alarm-light-outline',
        ]
      },
      {
        label: this._t('label_Danger'),
        icons: [
          'mdi mdi-alarm-light-outline',
          'mdi mdi-hazard-lights',
          'mdi mdi-triforce',
          'mdi mdi-molecule-co2',
          'mdi mdi-flash-alert-outline',
          'mdi mdi-bottle-tonic-skull-outline',
          'mdi mdi-skull-crossbones-outline',
          'mdi mdi-skull',
          'mdi mdi-virus-outline',
          'mdi mdi-bacteria-outline',


        ]
      },
      {
        label: this._t('label_Security'),
        icons: [
          'mdi mdi-shield-alert-outline',
          'mdi mdi-shield-key-outline',
          'mdi mdi-account-alert-outline',
          'mdi mdi-security-network',
          'mdi mdi-security',
          'mdi mdi-lock-open-alert',
          'mdi mdi-lock-open-outline',
          'mdi mdi-key-outline',
          'mdi mdi-key-remove',
          'mdi mdi-home-alert',
          'mdi mdi-home-lock-open',
          'mdi mdi-window-shutter-alert',
          'mdi mdi-volume-off',
        ]
      },
      {
        label: this._t('label_Maintenance'),
        icons: [
          'mdi mdi-broom',
          'mdi mdi-rake',
          'mdi mdi-hammer-wrench',
          'mdi mdi-wrench-outline',
        ]
      },
      {
        label: this._t('label_IT'),
        icons: [
          'mdi mdi-help-circle-outline',
          'mdi mdi-lan-disconnect',
          'mdi mdi-server-off',
          'mdi mdi-desktop-classic',
          'mdi mdi-content-save-alert-outline',
          'mdi mdi-disc-alert',
          'mdi mdi-timeline-alert-outline',
          'mdi mdi-folder-key-outline',
          'mdi mdi-folder-alert',
          'mdi mdi-table-alert',
          'mdi mdi-wifi-strength-alert-outline',
          'mdi mdi-restart-alert',

        ]
      },
      {
        label: this._t('label_Info_Calendar'),
        icons: [
          'mdi mdi-information-outline',
          'mdi mdi-information-variant',
          'mdi mdi-clock-alert-outline',
          'mdi mdi-calendar-alert',
          'mdi mdi-exclamation',
          'mdi mdi-exclamation-thick',
          'mdi mdi-clipboard-alert-outline',
          'mdi mdi-sticker-alert-outline',
          'mdi mdi-coffee-outline',
        ]
      },
      {
        label: this._t('label_Traffic'),
        icons: [
          'mdi mdi-bus-alert',
          'mdi mdi-subway-alert-variant',
          'mdi mdi-traffic-light',
        ]
      },
      {
        label: this._t('label_Technical'),
        icons: [
          'mdi mdi-coolant-temperature',
          'mdi mdi-radioactive',
          'mdi mdi-printer-3d-nozzle-alert-outline',
          'mdi mdi-tray-alert',
          'mdi mdi-beaker-alert-outline',
          'mdi mdi-water-percent-alert',
          'mdi mdi-thermometer-alert',
          'mdi mdi-thermometer-lines',
          'mdi mdi-oil-level',
          'mdi mdi-dishwasher-alert',
          'mdi mdi-battery-alert-variant-outline',
          'mdi mdi-vibrate',
          'mdi mdi-watch-vibrate',
          'mdi mdi-fuse-alert',
          'mdi mdi-engine-outline',
          'mdi mdi-fridge-alert-outline',
          'mdi mdi-state-machine',
          'mdi mdi-gas-cylinder',
          'mdi mdi-diving-scuba-tank',
          'mdi mdi-fan-alert',
          'mdi mdi-lightbulb-on-outline',
          'mdi mdi-power-plug-off-outline',
          'mdi mdi-car-tire-alert',
          'mdi mdi-lightning-bolt-outline',
          'mdi mdi-transmission-tower',
          'mdi mdi-scale-balance',
        ]
      },
      {
        label: this._t('label_Weather'),
        icons: [
          'mdi mdi-snowflake-alert',
          'mdi mdi-snowflake-melt',
          'mdi mdi-weather-cloudy-alert',
          'mdi mdi-weather-lightning',
          'mdi mdi-weather-pouring',
        ]
      },
      {
        label: this._t('label_media'),
        icons: [
          'mdi mdi-book-open-variant',
          'mdi mdi-cellphone-basic',
          'mdi mdi-cellphone',
          'mdi mdi-monitor',
          'mdi mdi-alarm-light-outline',
          'mdi mdi-phone-in-talk',
          'mdi mdi-email',
          'mdi mdi-card-text-outline',
          'mdi mdi-cellphone-text',
          'mdi mdi-message-text',
          'mdi mdi-cellphone-wireless',
          'mdi mdi-message-video',
          'mdi mdi-application-import',
          'mdi mdi-database-import',
          'mdi mdi-phone-voip',
          'mdi mdi-application-export',
          'mdi mdi-printer',
          'mdi mdi-database-export',
          'mdi mdi-motion-sensor',
          'mdi mdi-switch',
          'mdi mdi-serial-port',
          'mdi mdi-lighthouse',
          'mdi mdi-lighthouse',
          'mdi mdi-led-on',
          'mdi mdi-cup-water',
          'mdi mdi-gesture-tap-hold',
        ]
      }
    ]
    this.callRulesSimple = [
      {value: 'A', label: this._t('label_Plan_Option1_text')},
      {
        value: 'B',
        label: this._t('label_Plan_Option2_text')
      },
      {
        value: 'C',
        label: this._t('label_Plan_Option3_text')
      },
      {value: 'OTHER', label: 'Simple view configuration is not applicable'}
    ]
    this.selectRules = [
      {value: 'NONE', label: this._t('_NONE')},
      {value: 'RANDOM', label: this._t('label_random')},
      {value: 'ESCALATION', label: this._t('label_escalation')}
    ]
    this.callRules = [
      {value: 'ALL', label: this._t('ALL')},
      {value: 'PRECISELY', label: this._t('PRECISELY')},
      {value: 'MINIMUM', label: this._t('MINIMUM')}
    ]
    this.srcOptions = [
      {value: 'EXIST', label: this._t('label_Selected_plan')},
      {value: 'NEW', label: this._t('label_Define_new_plan')},
      {value: 'SIMPLE', label: this._t('label_Simplified_entry')},
    ]
    this.getUserProfile()
        .then(() => {
          this.initAlarmPoint()
          this.getAlarmPlans()
          this.checkOrgId()
          this.loadInstructions()
          if (this.$route.query.alarm_point_id || this.selectedAlarmPointId) {
            let alarmPointId = this.$route.query.alarm_point_id ?? this.selectedAlarmPointId
            if (alarmPointId.length > 0)
              this.selectedAlarmPoint.id = alarmPointId
            this.getAlarmPoint(alarmPointId ,true)
          } else {
            this.getAlarmPoints()
          }
        })
  },
  methods: {
    handleSaveTextTemplate(value) {
      this.selectedAlarmPoint.text = value
    },
    getUserProfile() {
      let localUser = localStorage.getItem('user');
      try {
        localUser = JSON.parse(localUser);
        return getUser(localUser?.id)
            .then(({data, status}) => {
              if (status === 200 && data.orgId) {
                this.orgId = data.orgId
                localStorage.setItem('orgId', data.orgId)
                this.selectedAlarmPoint.orgId = data.orgId
              }
            })
      } catch (e) {
        console.log(e);
        return Promise.reject(e)
      }
    },
    checkOrgId() {
      this.checkOrgIdIntervalId = setInterval(() => {
        if (this.orgId !== localStorage.getItem('orgId')) {
          this.orgId = localStorage.getItem('orgId')
          this.getAlarmPoints()
        }
      }, 100);
    },
    setOrgId(id) {
      this.orgId = id;
      this.getAlarmPoints()
    },
    initAlarmPoint() {
      this.selectedAlarmPoint = {
        id: null,
        version: 0,
        orgId: this.orgId ?? localStorage.getItem('orgId'),
        orgName: '',
        text: '',
        description: null,
        mediaUsageType: 'ALL',
        acknowledgeMode: 'NONE',
        confirmPriority: 1,
        mediaProcessingType: 'BY_RANK',
        instructionFile: null,
        speechText: null,
        localAcknowledgeTime: 0,
        countRepeat: 0,
        repeatBetween: 0,
        systemId: null,
        active: true,
        triggering: 'WHEN_ON',
        name: null,
        repeatMonInterval: 0,
        individualCalendarId: null,
        rotationalCalendarId: null,
        alarmPlanId: null,
        alarmRuleId: null,
        consequentialAlarms: [],
        alarmPointId: "",
        icon: {
          color: '#989898',
          name: 'mdi mdi-alert-outline'
        }
      }
    },
    getAlarmPoints($filter = '') {
      this.alarmPoints = []
      this.alarmPointSearch = $filter
      getList('ALARMPOINT', 0, this.alarmPointSearch, 100, this.orgId).then((response) => {
        this.alarmPoints = response.data.list
        this.alarmPoints.sort((a, b) => a.name.localeCompare(b.name))

        this.alarmPointsGrp = []

        this.alarmPoints.forEach(item => {
          let group = this.alarmPointsGrp.find((g) => g.label == item.name && g.orgId == item.orgId)
          if (!group) {
            group = {
              key: 'g' + this.alarmPointsGrp.length,
              label: item.name,
              selectable: false,
              children: [],
              type: 'group',
              orgId: item.orgId
            }
            this.alarmPointsGrp.push(group)
          }
          group.children.push({
            key: item.alarmPointId,
            label: item.alarmPointId,
            selectable: true,
            type: 'alarm',
            data: item,
            orgId: item.orgId
          })
        }, this)

        this.alarmPointsGrp = this.alarmPointsGrp.map((item) => {
          if (item.children.length < 2) {
            item = item.children[0]
            item.label = item.data.name
          }
          return item
        }, this)
      })
    },

    getAlarmPoint(id, forceListFilter = false) {
      getAlarmPoint(id).then((response) => {
        this.selectedAlarmPoint = response.data

        if (forceListFilter) {
          this.getAlarmPoints(this.selectedAlarmPoint.name)
        }

        if (this.selectedAlarmPoint.confirmPriority === 0 || this.selectedAlarmPoint.confirmPriority === null) {
          this.selectedAlarmPoint.confirmPriority = 1
        }
        if (this.selectedAlarmPoint.repeatMonInterval && this.selectedAlarmPoint.repeatMonInterval != 0) {
          this.repeatableMonitored = true
        }

        if (this.selectedAlarmPoint.alarmPlanId) {
          this.getAlarmPlan(this.selectedAlarmPoint.alarmPlanId)
        }
        try {
          this.selectedAlarmPoint.icon = JSON.parse(this.selectedAlarmPoint.icon)
        } finally {
          if (!this.selectedAlarmPoint.icon || typeof this.selectedAlarmPoint.icon !== 'object') {
            this.selectedAlarmPoint.icon = {
              color: '#989898',
              name: 'mdi mdi-alert-outline'
            }
          }
        }
        this.srcOption = 'EXIST'
        this.$forceUpdate()
      })
    },
    copyTextVar() {
      this.selectedAlarmPoint.text = (this.selectedAlarmPoint.text ?? '') + this.selectedTextVar
      this.selectedTextVar = ''
    },
    syncSrcOptions() {
      switch (this.srcOption) {
        case 'EXIST':
          break
        case 'NEW':
          this.createAlarmPlan()
          break
        case 'SIMPLE':
          this.createAlarmPlan(true)
          this.activeTabSimple = 0
          break
      }
    },
    validateAlarmPoint() {
      this.alarmPointError = []
      if (this.selectedAlarmPoint.text.length === 0) {
        this.alarmPointError.push('selectedAlarmPoint.text')
      }
    },
    initCallGroupEditDialog(callGroup) {
      this.originalCallGroup = callGroup
      this.editCallGroup.members = Array.from(this.originalCallGroup.members)
      this.editCallGroupDialog = true
    },
    initCallGroupDeleteDialog(index) {
      this.deleteCallGroupIndex = index
      this.deleteCallGroupDialog = true
    },
    deleteCallGroup() {
      this.deleteCallGroupDialog = false
      this.selectedAlarmPlan.callGroups.splice(this.deleteCallGroupIndex, 1)
      this.deleteCallGroupIndex = -1
    },
    reorderCallGroupMembers(callGroup, ordered) {
      callGroup.members = ordered
      callGroup.members.forEach(function (member, index) {
        member.orderId = index
      })
    },
    updateCallGroupMembers() {
      this.originalCallGroup.members = Array.from(this.editCallGroup.members)
      this.editCallGroupDialog = false
      if (this.editCallGroup.simple) {
        this.syncGroupSelectRuleSimple(this.originalCallGroup)
      }
    },
    removeCallGroupMembers(callGroup, simple) {
      let selected = this.selectedCallGroupMembers[callGroup.id].filter(() => true);
      this.selectedCallGroupMembers[callGroup.id] = [];
      for (const value of selected) {
        if (value.participantId) {
          callGroup.members.splice(callGroup.members.findIndex(o => o.participantId == value.participantId), 1)
        } else if (value.participantGroupId) {
          callGroup.members.splice(callGroup.members.findIndex(o => o.participantGroupId == value.participantGroupId), 1)
        }
      }
      if (simple) {
        this.syncGroupSelectRuleSimple(callGroup)
      }
    },
    syncGroupSelectRuleSimple(callGroup) {
      if (callGroup.memberCallRuleSimple == 'OTHER') {
        this.activeTabSimple = 0
        this.syncGroupMemberCallRule(callGroup)
        return
      }
      if (this.hasParticipantGroups(callGroup)) {
        switch (callGroup.memberCallRuleSimple) {
          case 'A':
            callGroup.memberCallRule = 'ALL'
            callGroup.isRandom = false
            callGroup.isEscalation = true
            callGroup.memberSelectRule = 'ESCALATION'
            break;
          case 'B':
            callGroup.memberCallRule = 'ALL'
            callGroup.isRandom = false
            callGroup.isEscalation = true
            callGroup.memberSelectRule = 'ESCALATION'
            break;
          case 'C':
            callGroup.memberCallRule = 'ALL'
            callGroup.isRandom = true
            callGroup.isEscalation = false
            callGroup.memberSelectRule = 'RANDOM'
            break;
        }
      } else {
        switch (callGroup.memberCallRuleSimple) {
          case 'A':
            callGroup.memberCallRule = 'ALL'
            callGroup.isRandom = true
            callGroup.isEscalation = false
            callGroup.memberSelectRule = 'RANDOM'
            break;
          case 'B':
            callGroup.memberCallRule = 'ALL'
            callGroup.isRandom = true
            callGroup.isEscalation = false
            callGroup.memberSelectRule = 'RANDOM'
            break;
          case 'C':
            callGroup.memberCallRule = 'ALL'
            callGroup.isRandom = true
            callGroup.isEscalation = false
            callGroup.memberSelectRule = 'RANDOM'
            break;
        }
      }
    },
    syncGroupSelectRule(callGroup) {
      if (callGroup.memberSelectRule == 'RANDOM') {
        callGroup.isRandom = true
        callGroup.isEscalation = false
      } else if (callGroup.memberSelectRule == 'ESCALATION') {
        callGroup.isRandom = false
        callGroup.isEscalation = true
      }
      this.syncGroupMemberCallRule(callGroup)
    },
    syncGroupMemberCallRule(callGroup) {
      if (this.hasParticipantGroups(callGroup)) {
        if (callGroup.memberCallRule == 'ALL' && callGroup.isRandom == false && callGroup.isEscalation == true) {
          callGroup.memberCallRuleSimple = 'A'
        } else if (callGroup.memberCallRule == 'MINIMUM' && callGroup.isRandom == false && callGroup.isEscalation == true) {
          callGroup.memberCallRuleSimple = 'B'
        } else if (callGroup.memberCallRule == 'MINIMUM' && callGroup.isRandom == true && callGroup.isEscalation == false) {
          callGroup.memberCallRuleSimple = 'C'
        } else {
          callGroup.memberCallRuleSimple = 'OTHER'
        }
      } else {
        if (callGroup.memberCallRule == 'ALL' && callGroup.isRandom == true && callGroup.isEscalation == false) {
          callGroup.memberCallRuleSimple = 'A'
        } else if (callGroup.memberCallRule == 'MINIMUM' && callGroup.isRandom == true && callGroup.isEscalation == false) {
          callGroup.memberCallRuleSimple = 'B'
        } else if (callGroup.memberCallRule == 'PRECISELY' && callGroup.isRandom == true && callGroup.isEscalation == false) {
          callGroup.memberCallRuleSimple = 'C'
        } else {
          callGroup.memberCallRuleSimple = 'OTHER'
        }
      }
    },
    getAlarmPlan(id) {
      getAlarmPlan(id).then((response) => {
        this.selectedAlarmPlan = response.data
        this.selectedAlarmPlan.callGroups.forEach(function (callGroup) {
          if (callGroup.isRandom == true && callGroup.isEscalation == false) {
            callGroup.memberSelectRule = 'RANDOM'
          } else if (callGroup.isRandom == false && callGroup.isEscalation == true) {
            callGroup.memberSelectRule = 'ESCALATION'
          } else {
            callGroup.memberSelectRule = 'NONE'
          }
          this.syncGroupMemberCallRule(callGroup)
        }, this)
      })
    },
    getAlarmPlans() {
      getList('ALARMPLAN', 0, this.apFilterValue, 1000).then((response) => {
        this.alarmPlans = response.data.list
        this.alarmPlans.sort((a, b) => a.name.localeCompare(b.name))
        this.currentPage = response.data.currentPage
        this.totalRecords = response.data.count
        this.loading = false
      })
    },
    createCallGroup() {
      this.selectedAlarmPlan.callGroups = Array.isArray(this.selectedAlarmPlan.callGroups) ? this.selectedAlarmPlan.callGroups : []

      this.newCallGroup.id = '_' + this.selectedAlarmPlan.callGroups.length
      this.newCallGroup.orgId = this.selectedAlarmPlan.orgId
      this.newCallGroup.orgName = this.selectedAlarmPlan.orgName
      this.newCallGroup.alarmPlanId = this.selectedAlarmPlan.id
      this.newCallGroup.atLeastCount = 1
      this.newCallGroup.memberCallRule = 'ALL'
      this.newCallGroup.isEscalation = false
      this.newCallGroup.isRandom = true
      this.newCallGroup.memberSelectRule = 'RANDOM'
      this.newCallGroup.memberCallRuleSimple = 'C'
      this.newCallGroup.members = []
      this.newCallGroup.version = 0
      this.newCallGroup.orderId = this.selectedAlarmPlan.callGroups ? this.selectedAlarmPlan.callGroups.length : 0

      let callGroup = Object.assign({}, this.newCallGroup)
      this.selectedAlarmPlan.callGroups.push(callGroup)
      this.newCallGroupDialog = false
      this.newCallGroup = {}
    },
    hasParticipantGroups(callGroup) {
      return callGroup.members.findIndex(o => o.participantGroupId != null) != -1
    },
    createAlarmPlan(simple = false) {
      this.selectedAlarmPlan = {
        id: null,
        name: simple ? 'Participant plan for ' + this.selectedAlarmPoint.name : '',
        description: simple ? 'Automatically generated participant plan for ' + this.selectedAlarmPoint.name : '',
        orgId: this.selectedAlarmPoint.orgId,
        orgName: this.selectedAlarmPoint.orgName,
        callGroups: []
      }

      this.createCallGroup()
    },
    executeManualCall() {
      let groups = [...this.selectedAlarmPlan.callGroups]
      groups.forEach(function (group) {
        if (group.id) {
          delete group.id
        }
        if (group.alarmPlanId) {
          delete group.alarmPlanId
        }

        group.members.forEach(function (member) {
          delete member.id
          delete member.planGroupId
        })
      })

      const data = {
        icon: JSON.stringify(this.selectedAlarmPoint.icon),
        instructionFiles: this.selectedAlarmPoint.instructionFiles,
        acknowledgeMode: this.selectedAlarmPoint.acknowledgeMode,
        alarmplanContent: {
          callGroups: groups,
          description: this.selectedAlarmPlan.description,
          name: this.selectedAlarmPlan.name,
          orgId: this.selectedAlarmPlan.orgId,
          orgName: this.selectedAlarmPlan.orgName,
        },
        confirmPriority: this.selectedAlarmPoint.confirmPriority,
        description: this.selectedAlarmPoint.description,
        mediaUsageType: this.selectedAlarmPoint.mediaUsageType,
        name: this.selectedAlarmPoint.name,
        speechText: this.selectedAlarmPoint.speechText,
        text: this.selectedAlarmPoint.text
      }
      manualCall(data).then(() => {
        this.$root.showMessage(this._t('label_alarm_triggered'), 'success')
        this.initAlarmPoint()
        this.$router.push(`/alarm-table`)
      }).catch((error) => {
        console.log(error)
        let data = error.response.data ? error.response.data instanceof Object ? error.response.data : JSON.parse(error.response.data) : {}
        this.$root.showMessage((data.reason ? ': ' + data.reason : ''), 'error')
      })
    },
    downloadInstruction(instructionFile) {
      downloadInstruction(instructionFile)
    },
    deleteInstruction(instruction) {
      this.selectedAlarmPoint.instructionFiles
          .splice(this.selectedAlarmPoint.instructionFiles.findIndex(i => i === instruction), 1)
    },
    loadInstructions() {
      getInstructionFiles('')
          .then(res => {
            if (res) {
              this.instructions = this.getInstructionsRecursive(res.data.instructions)
              if (this.instructions.length) {
                this.instructions.sort((a, b) =>
                    a.org.fullOrgPath.localeCompare(b.org.fullOrgPath)
                    || a.name.localeCompare(b.name)
                )
              }
            }
          })
    },
    addInstruction() {
      if (!this.selectedAlarmPoint.instructionFiles) {
        this.selectedAlarmPoint.instructionFiles = [];
      }
      if (this.selectedAlarmPoint.instructionFiles.indexOf(this.selectedAlarmPoint.instructionFile) === -1) {
        this.selectedAlarmPoint.instructionFiles.push(this.selectedAlarmPoint.instructionFile);
      }
      this.selectedAlarmPoint.instructionFile = null;
    },
    getInstructionsRecursive(instructions, collection = [], org = {}) {
      for (const instruction of instructions) {
        if (instruction.files && instruction.files.length) {
          collection.concat(this.getInstructionsRecursive(instruction.files, collection, instruction.org))
        } else {
          collection.push({...instruction, org})
        }
      }
      return collection
    },
    getUserGroups() {
      if (this.lazyLoadUGRecords) {
        getList('USERGROUP', this.userGroupPage, '', 50, this.orgId).then((response) => {
          if (response.data.list.length === 0) {
            this.lazyLoadUGRecords = false
          }
          this.userGroups = [...this.userGroups, ...response.data.list]
          this.userGroupPage++

        })
      }
    },
  },

  computed: {
    manualCallEnabled: function () {
      if (this.selectedAlarmPoint && this.selectedAlarmPoint.name && this.selectedAlarmPoint.confirmPriority && this.selectedAlarmPoint.text && this.selectedAlarmPoint.text.length
          && this.selectedAlarmPlan
          && this.selectedAlarmPlan.callGroups && this.selectedAlarmPlan.callGroups.length > 0) {
        let flag = false
        this.selectedAlarmPlan.callGroups.forEach((group) => {
          if (group.memberSelectRule && group.memberCallRule && group.atLeastCount > 0 && group.members && group.members.length) {
            flag = true
          }
        }, this)
        return flag
      } else {
        return false
      }
    }
  },
}
</script>
