<template>
  <AlarmTrigger>
  </AlarmTrigger>
</template>

<script>
import AlarmTrigger from "../../components/AlarmTrigger.vue";

export default {
  name: 'index',
  components: {
    AlarmTrigger
  },
}
</script>
